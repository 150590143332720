.wrapper {
  background-color: #000;
  width: 100%;
  height: 100vh;
  position: relative;

  @media (max-width: 1300px) {
    height: auto;
  }

  @media screen and (max-height: 760px) {
    height: auto;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
}

.introImage {
  width: 830px;

  @media (max-width: 1300px) {
    width: 100%;
    max-width: 400px;
  }
}

.loginForm {
  width: 480px;
  height: 540px;
  background: #fff;
  padding: 50px;

  @media (max-width: 1300px) {
    width: 100%;
    height: auto;
    padding: 50px 20px;
  }
}

.logo {
  margin: 0 auto;
  // width: 100%;

  img {
    width: 100%;
  }

  @media (max-width: 1300px) {
    max-width: 400px;
    margin: 0 auto;
  }
}

.header {
  font-size: 18px;
  color: var(--dark-color);
  font-weight: 500;
  text-align: center;
  margin-top: 55px;
}

.inputs {
  display: flex;
  flex-direction: column;
  margin: 30px 0 40px 0;
  gap: 20px;

  input {
    background-color: var(--old-gray-color);
    outline: none;
    font-size: 14px;
    padding: 0px 15px;
    height: 50px;
    border: none;
  }
}

.submitButton {
  margin-top: 48px;
  background-color: var(--green-color);
  font-size: 14px;
  color: var(--white-color);
  font-weight: 700;
  border: 1px solid var(--green-color);
  transition: all 0.2s;
  text-align: center;
  height: 50px;
  width: 100%;
  padding: 0 24px;
  cursor: pointer;

  &:hover {
    color: var(--green-color);
    background-color: var(--dark-green-color);
    border: 1px solid var(--dark-green-color);
  }
}

.orDivider {
  text-align: center;
  margin: 20px 0;
  font-size: 14px;
  font-weight: 500;
}

.additionalButtonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.additionalButtons {
  background-color: var(--old-gray-color);
  font-size: 14px;
  color: var(--dark-color);
  border: 1px solid var(--old-gray-color);
  transition: all 0.2s;
  text-align: center;
  height: 50px;
  width: 100%;
  padding: 0 24px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: var(--dark-color);
    background-color: #fff;
  }
}

.returnButton {
  text-decoration: none;
  text-align: center;
  width: 100%;
  margin-top: 60px;
  font-size: 15px;
  font-weight: 600;
  color: var(--red-color);
  display: inline-block;
}

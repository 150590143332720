.wrapper {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto 64px auto;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0;

  h1 {
    margin-bottom: 16px;
  }

  p {
    width: 70%;
    font-size: 15px;
    margin: 0 auto;
    text-align: center;
  }
}

.userPromptWrapper {
  display: flex;

  input {
    background-color: transparent;
    border: 1px solid var(--green-color);
    outline: none;
    padding: 20px 15px;
    min-width: 500px;
    font-size: 15px;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    background-color: var(--green-color);
    color: var(--white-color);
    font-weight: 700;
    padding: 0 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    transition: all 0.2s;
    font-size: 14px;
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  button:hover {
    background-color: var(--green-color);
    color: var(--dark-color);

    span {
      border-color: var(--green-color) var(--green-color) transparent !important;
    }
  }
}

.membershipErrorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  text-align: center;

  h2 {
    margin-bottom: 16px;
  }

  p {
    font-size: 15px;
  }

  a {
    margin-top: 36px;
    font-size: 15px;
    color: var(--green-color);
  }
}

.userPrompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 48px 0;
}

.level {
  display: flex;
  gap: 26px;
}

.features {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 32px;
  border: 1px solid var(--gray-color);
  padding: 16px 32px;
  margin-top: 16px;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.feature {
  font-size: 15px;
  width: max-content;
}

input[type="radio"] {
  --radio-size: 20px;

  height: var(--radio-size);
  aspect-ratio: 1;
  border: calc(var(--radio-size) / 8) solid #939393;
  padding: calc(var(--radio-size) / 8);
  background: radial-gradient(farthest-side, var(--green-color) 94%, #0000) 50%/0
    0 no-repeat content-box;
  border-radius: 50%;
  outline-offset: calc(var(--radio-size) / 10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: inherit;
  transition: 0.3s;
}

input[type="radio"]:checked {
  border-color: var(--green-color);
  background-size: 100% 100%;
}

input[type="radio"]:disabled {
  background: linear-gradient(#939393 0 0) 50%/100% 20% no-repeat content-box;
  opacity: 0.5;
  cursor: not-allowed;
}

label {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin: 5px 0;
  cursor: pointer;
}

.generatedPromptWrapper {
  p {
    font-size: 18px;
    font-weight: bold;
  }
}

.generatedPrompt {
  width: 100%;
  height: 350px;
  border: 1px solid var(--light-gray-color);
  margin: 16px 0 32px 0;
  padding: 20px 15px;
  font-size: 15px;
  transition: all 0.2s;
  background-color: var(--gray-color);
  resize: none;
  outline: none;

  &:not(:disabled):hover {
    background-color: #fff;
    cursor: copy;
  }
}

.copyButton {
  outline: none;
  background-color: var(--green-color);
  color: var(--white-color);
  font-weight: 700;
  padding: 12px 24px;
  cursor: pointer;
  border: 1px solid var(--green-color);
  transition: all 0.2s;
  font-size: 14px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  // &:hover {
  //   background-color: var(--dark-green-color);
  //   color: var(--green-color);
  //   border: 1px solid var(--dark-green-color);
  // }
}

@media screen and (max-width: 1450px) {
  .wrapper {
    max-width: unset;
    width: 100%;
    padding: 0 64px;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {
    padding: 0 32px;
  }

  .hero {
    p {
      width: 100%;
      font-size: 15px;
    }
  }
}

@media screen and (max-width: 768px) {
  .userPromptWrapper {
    width: 100%;

    input {
      min-width: unset;
      width: 100%;
    }

    button {
      width: 180px;
    }
  }
}

@media screen and (max-width: 550px) {
  .wrapper {
    padding: 0 16px;
  }

  .level {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .features {
    flex-wrap: wrap;
  }
}

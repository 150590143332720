.menu {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 1280px;
  margin: 40px auto;
}

.menuLeft,
.menuRight {
  display: flex;
  align-items: center;
  gap: 16px;
}

.menuButtons {
  display: flex;
  align-items: center;
  // background: red;
  // width: 100%;
}

.menuLeft {
  margin-right: 10px;
}

.menuLogo {
  max-width: 250px;
  margin-right: auto;
  cursor: pointer;
}

.menuLink {
  text-decoration: none;
  font-size: 15px;
  padding: 5px 0px;
  margin: 0px 10px;
  font-weight: 700;
  color: var(--dark-color);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2px;
    background-color: black;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
  }

  &:hover::after {
    transform: scaleX(1);
  }

  &:not(:hover)::after {
    transform-origin: right;
    transform: scaleX(0);
  }
}

.menuLink.activeLink::after {
  transform: scaleX(1);
}

.menuLink.sublink:hover {
  color: var(--green-color);
}

.menuLink.sublink::after {
  transform: scaleX(0);
}

.menuLink.sublink.activeLink {
  color: var(--green-color) !important;
}

.mobileMenuIcon {
  display: none;
  cursor: pointer;
  margin-right: 32px;
}

.mobileMenuIcon div {
  width: 25px;
  height: 3px;
  background-color: var(--dark-color);
  margin: 5px 0;
  transition: all 0.3s ease;
}

.mobileMenuIcon .open:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.mobileMenuIcon .open:nth-child(2) {
  opacity: 0;
}

.mobileMenuIcon .open:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

@media screen and (min-width: 1101px) {
  .menuLeft {
    display: block !important;
  }
}

@media (max-width: 396px) {
  .menu {
    flex-direction: column;
    gap: 6px;
  }

  .menuButtons {
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 1450px) {
  .menu {
    max-width: unset;
    width: 100%;
    padding: 0 64px;
  }
}

@media (max-width: 1100px) {
  .mobileMenuIcon {
    display: block;
  }

  .menuLeft {
    display: none;
    position: absolute;
    top: 100%;
    background-color: #fff;
    height: fit-content;
    width: calc(100% - 128px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 1000;
    gap: 32px;
    padding: 20px 0px;

    a {
      font-size: 15px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .menu {
    padding: 0 32px;
  }

  .menuLeft {
    width: calc(100% - 64px);
  }
}

@media screen and (max-width: 550px) {
  .menu {
    padding: 0 16px;
  }

  .menuLogo {
    max-width: 180px;
  }

  .menuLeft {
    width: calc(100% - 32px);
    right: 6px;
  }
}

.toolsDropdown {
  position: relative;
  display: inline-block;
  background-color: white;
}

.toolsDropdown button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.dropdownContent {
  display: block;
  position: absolute;
  background-color: white;
  width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

@media screen and (max-width: 1100px) {
  .dropdownContent {
    width: 200px;
    position: static;
    box-shadow: none;
  }
  .toolsDropdown {
    text-align: center;
    width: 200px;
  }
  .dropdownContent .menuLink {
    text-decoration: none;
    color: var(--dark-color);
    font-size: 14px;
    padding: 5px 10px;

    &:hover {
      background-color: white;
      color: black;
    }
  }
}

.toolsDropdown .dropdownContent {
  display: block;
}

.dropdownContent .menuLink {
  display: block;
  padding: 12px 16px;
  text-decoration: none;
  color: black;
}

.primaryButtonWrapper {
  background-color: transparent;
  border: none;
  outline: none;
  background-color: var(--green-color);
  color: #fff;
  padding: 10px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  transition: all 0.2s;
  border: 1px solid var(--green-color);
  margin-top: 32px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #fff;
    color: var(--green-color);
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: row;
  position: relative;
  gap: 3px;

  img {
    width: 25px;
  }

  svg {
    margin-top: 2px;
    width: 10px;
  }
}

.tooltipWrapper {
  position: absolute;
  top: 100%;
  right: 0px;
  z-index: 999;
  width: 146px;
  height: auto;
  padding: 10px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tooltipWrapperOpen {
  opacity: 1;
  background-color: #fff;
  transform: translateY(0);
  visibility: visible;
  font-size: 14px;
  display: flex;
}

.tooltipItem {
  display: flex;
  gap: 4px;
  display: flex;
  align-items: center;
  padding: 6px;

  &:hover {
    color: #43b02a;
  }
}
